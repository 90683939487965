import Vue from 'vue';
import Component from 'vue-class-component';
import { Validation } from 'vuelidate';


/**
 * Helper class that creates error messages according an error object. If more than one error is contained inside the error object, only the
 * error with the biggest priority is returned.
 */
@Component
export default class ErrorMessageHandlerMixin extends Vue { 

    /**
     * The definition of the error priorities. The smaller the index, the bigger the priority.
     */
    private priorities = [
        'required',
        'decimalNumber',
        'isTimeFormat',
        'minLength',
        'maxLength',
        'numeric',
        'decimal',
        'minValue',
        'maxValue',
        'email',
        'sameAsPassword',
        'url'
    ];

    /**
     * Handles the identification of the needed error message. Returns null if no error is present.
     * @param validation
     */
    public identifyErrorMessage(validation: Validation): string | null {
        if (validation === undefined) {
            throw new Error('Validation model property binding is not correct. Is there a typo?');
        }
        if (validation.$dirty && validation.$error) {
            for (const errorType of this.priorities) {
                if (errorType in validation && !(validation as any)[errorType]) {
                    const params = (validation as any).$params[errorType];
                    return this.$t('GENERAL.VALIDATION_MESSAGES.' + errorType.toUpperCase(), params).toString();
                }
            }
            // if no error message was provided for this error
            return this.$t('GENERAL.VALIDATION_MESSAGES.UNDEFINED').toString();
        } else {
            return null;
        }
    }

    /**
     * Triggers the validation for the given propertyPath. That means:
     * propertyPath = "customer.address.street" triggers the validation for customer.address.street
     * @param propertyPath
     * Returns true, if valid, false if invalid
     */
    public triggerValidation(propertyPath: string): boolean {
        const parts = propertyPath.split('.');
        let validationObject = (this.$v as any)[parts.shift()!];
        while (parts.length > 0) {
            validationObject = validationObject[parts.shift()!];
        }
        validationObject.$touch();
        return !validationObject.$invalid;
    }
}
